<template>
  <InertiaHead :title="__('general.title')" />

  <div
    class="bg-gray-100 bg-cover bg-center"
    :style="{ backgroundImage: `url(${asset('background-header.png')})` }"
  >
    <div class="bg-secondary bg-opacity-60">
      <SurveyForm />
    </div>
  </div>

  <div class="bg-white py-10 sm:py-16">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-xl text-center">
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Why Trending Jobs?
        </p>
      </div>
      <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
          <div class="flex flex-col">
            <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
              Millions of available jobs
            </dt>
            <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto">
                Every day we add thousands of new available jobs. We provide you a complete overview of vacancies from different job providers. And it’s completely free!
              </p>
            </dd>
          </div>
          <div class="flex flex-col">
            <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
              Sign up in seconds
            </dt>
            <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto">
                You can create a job search in under a minute and when you sign-up you will receive daily job alerts with available jobs from your area. No strings attached.
              </p>
            </dd>
          </div>
          <div class="flex flex-col">
            <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
              We helped millions of people
            </dt>
            <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto">
                Trending Jobs is founded in 2016 and since then we’ve helped millions of job seekers finding their dream job. We make it very easy to see available jobs in your area.
              </p>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>

  <div class="relative isolate bg-white pb-32 pt-10 sm:pt-16">
    <div
      class="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
      aria-hidden="true"
    >
      <div
        class="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#1789CA] to-[#1789CA]"
        style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
      />
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-xl text-center">
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Testimonials
        </p>
      </div>
      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
        <div class="space-y-8 xl:contents xl:space-y-0">
          <div class="space-y-8 xl:row-span-2">
            <figure class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
              <blockquote class="text-gray-900">
                <p>“I have been looking for jobs, and Trending Jobs has never stopped sending me applications. Love it! ”</p>
              </blockquote>
            </figure>
          </div>
          <div class="space-y-8 xl:row-start-1">
            <figure class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
              <blockquote class="text-gray-900">
                <p>“Trending Jobs has a clear & simple to navigate website which makes looking for the right job a straightforward process. In turn, this helps to take out a lot of the stress commonly associated when searching for a new job. I would highly recommend this website.”</p>
              </blockquote>
            </figure>
          </div>
        </div>
        <div class="space-y-8 xl:contents xl:space-y-0">
          <div class="space-y-8 xl:row-start-1">
            <figure class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
              <blockquote class="text-gray-900">
                <p>“With Trending Jobs, I was able to search and find my new position with ease. Two of the best features are accurate filtering and having so many available jobs. Thanks for helping me find a great match!”</p>
              </blockquote>
            </figure>
          </div>
          <div class="space-y-8 xl:row-span-2">
            <figure class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
              <blockquote class="text-gray-900">
                <p>“All those jobs being updated by the minute is great and being able to find different types of jobs near me saves so much time for me.”</p>
              </blockquote>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white py-10 sm:py-16">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-xl text-center">
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Companies that are hiring
        </p>
      </div>
      <div class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
        <img
          class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
          src="/companies/amazon.png"
          alt="Amazon"
          height="48"
        >
        <img
          class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
          src="/companies/aldi.png"
          alt="Aldi"
          height="48"
        >
        <img
          class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
          src="/companies/fedex.png"
          alt="Fedex"
          height="48"
        >
        <img
          class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
          src="/companies/wallmart.png"
          alt="Wallmart"
          height="48"
        >
        <img
          class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
          src="/companies/costco.png"
          alt="Costco"
          height="48"
        >
      </div>
    </div>
  </div>

  <div class="bg-white py-10 sm:py-16">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-xl text-center">
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Avaliable jobs
        </p>
      </div>
      <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
        <div class="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
          <a
            v-for="job in jobTitles"
            :key="job"
            class="block p-4 bg-gray-100 rounded-lg text-center text-lg font-semibold text-gray-900 hover:bg-gray-200 cursor-pointer"
            @click="searchJob(job)"
          >
            {{ job }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <OptInConfirmModal ref="optin-confirm" />
  <LoggedOutModal ref="logged-out" />
  <ErrorModal ref="error" />
</template>

<script>
import SurveyForm from './Components/SurveyV2/Form.vue';
import OptInConfirmModal from './Components/Modals/OptInConfirmModal.vue';
import ErrorModal from '../Auth/Components/ErrorModal.vue';
import LoggedOutModal from './Components/Modals/LoggedOutModal.vue';

export default {
  components: {
    LoggedOutModal,
    ErrorModal,
    OptInConfirmModal,
    SurveyForm,
  },

  props: {
    modal: {
      required: false,
      type: String,
      default: null,
    },
  },

  data() {
    return {
      jobTitles: [
        'Admin',
        'Customer Service',
        'Delivery Driver',
        'Entry Level',
        'Nurse',
        'Restaurant',
        'Retail Associate',
        'Sales',
        'Truck Driver',
        'Warehouse Worker',
        'Work from home',
        'Stocker',
      ],
    };
  },

  mounted() {
    if (this.modal && this.modal in this.$refs) {
      this.$refs[this.modal].open();
    }

    if (this.modal === 'logged-out') {
      this.$tracker.logout();
    }
  },

  methods: {
    searchJob(job) {

      const query = {
        query: job,
        country: 'US',
        age: 14,
        radius: 40,
      };

      this.$inertia.visit(this.route('frontend.jobs', query));
    },
  },
};
</script>
