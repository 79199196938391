<template>
  <main class="container w-full md:w-3/5">
    <div class="mb-8 md:mb-16 mt-12">
      <button
        v-show="currentStep > 1"
        type="button"
        class="flex items-center group"
        @click="previousQuestion"
      >
        <span class="fa fa-chevron-left text-gray-400 text-sm mr-2" />
        <div class="group-hover:underline">
          {{ __('survey.form.previous') }}
        </div>
      </button>
    </div>

    <div class="flex mb-8 md:mb-12 relative rounded mx-2 bg-gradient-to-r from-primary to-primary-dark">
      <template
        v-for="i in totalSteps"
        :key="`step-${i}`"
      >
        <div class="relative grow border-l-4 border-white h-1.5">
          <template v-if="i === currentStep">
            <div class="absolute -top-2 -left-2 text-primary">
              <CurrentIndexIcon />
            </div>
          </template>
        </div>
      </template>

      <div class="absolute -top-2 -right-2 text-primary-dark">
        <FinalIndexIcon />
      </div>
    </div>

    <div v-show="!loading && currentStep === 1">
      <KeywordsQuestion @next="nextQuestion" />
    </div>
    <div v-show="!loading && currentStep === 2">
      <LocationQuestion @next="nextQuestion" />
    </div>
    <div v-show="!loading && currentStep === 3">
      <JobTypeQuestion @next="nextQuestion" />
    </div>
    <div v-show="!loading && currentStep === 4">
      <EnableJobAlertsQuestion @next="nextQuestion" />
    </div>
    <div v-show="!loading && currentStep === 5">
      <EmailQuestion @next="nextQuestion" />
    </div>

    <div v-show="loading">
      <div class="mt-14 text-center">
        <span class="fa fa-spinner-third animate-spin text-6xl text-primary" />
      </div>
    </div>
  </main>
</template>

<script>
import { usePage } from '@inertiajs/vue3';
import { useStore } from 'vuex';
import KeywordsQuestion from './KeywordsQuestion.vue';
import LocationQuestion from './LocationQuestion.vue';
import JobTypeQuestion from './JobTypeQuestion.vue';
import EnableJobAlertsQuestion from './EnableJobAlertsQuestion.vue';
import EmailQuestion from './EmailQuestion.vue';
import CurrentIndexIcon from '../../../../Icons/Survey/CurrentIndex.vue';
import FinalIndexIcon from '../../../../Icons/Survey/FinalIndex.vue';
import Search from '../../../../Mixins/Search.js';

export default {
  components: {
    CurrentIndexIcon,
    FinalIndexIcon,
    KeywordsQuestion,
    LocationQuestion,
    JobTypeQuestion,
    EmailQuestion,
    EnableJobAlertsQuestion,
  },

  mixins: [Search],

  setup() {
    const page = usePage();
    useStore().commit('SurveyForm/clearJobSearch');
    useStore().commit('SurveyForm/setRequestData', page.props.requestData);
  },

  data() {
    return {
      totalSteps: this.$page.props.loggedIn ? 3 : 5,
      currentStep: 1,
      loading: false,
    };
  },

  methods: {
    nextQuestion(forceStep = null) {
      if (forceStep) {
        this.currentStep = forceStep;
      } else {
        this.currentStep += 1;
      }

      if (this.currentStep > this.totalSteps) {
        this.search();
      }
    },

    previousQuestion() {
      if (this.currentStep <= 1) {
        return;
      }

      this.currentStep -= 1;
    },

    search() {
      this.loading = true;

      const query = this.jobSearchToQuery(this.$store.state.SurveyForm.jobSearch);
      this.$inertia.visit(this.route('frontend.jobs', query));
    },
  },
};
</script>
