<template>
  <InertiaHead :title="__('terms-and-conditions.title')" />
  <main class="content container md:px-12 mt-8 md:mt-18">
    <h1>
      {{ __('terms-and-conditions.title') }}
    </h1>
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="__('terms-and-conditions.html')" />
    <!-- eslint-enable vue/no-v-html -->
  </main>
</template>

<script>
import {usePage} from '@inertiajs/vue3';
export default {
  setup() {
    const page = usePage();
    return {
      email: page.props.config.email,
    };
  },
};
</script>
