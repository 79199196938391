<template>
  <input
    ref="input"
    type="tel"
    autocomplete="tel"
    class="form-input w-full"
    @input="update"
  >
</template>

<script>
import 'intl-tel-input/build/js/utils';
import intlTelInput from 'intl-tel-input';

export default {
  props: {
    modelValue: {
      required: false,
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      input: null,
    };
  },

  mounted() {
    this.input = intlTelInput(this.$refs['input'], {
      initialCountry: this.$page.props.requestData.country,
    });

    this.input.setNumber(this.modelValue);
  },

  methods: {
    update(e) {
      console.log(this.input.getNumber());
      if (e && e.target.value === '') {
        this.$emit('update:modelValue', '');
      }

      this.$emit('update:modelValue', this.input.getNumber());
    },
  },
};
</script>
