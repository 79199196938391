<template>
  <div :class="{ 'has-error': showError }">
    <input
      ref="input"
      type="text"
      class="form-input w-full"
      :placeholder="placeholder"
    >
  </div>
</template>

<script>
import Tagify from '@yaireo/tagify';
import axios from 'axios';

export default {
  props: {
    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    modelValue: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    loadInitialFromValue: {
      type: Boolean,
      required: false,
      default: true,
    },

    showError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  data () {
    return {
      timeout: null,

      tagify: null,

      tagifySettings: {
        mode: 'select',
        whitelist: [],
        delimiters: null,

        dropdown: {
          enabled: 0,
          highlightFirst: true,
          searchKeys: [],
        },

        callbacks: {
          input: this.tagifyInput,
          change: this.update,
          remove: this.cleared,
        },
      },
    };
  },

  mounted () {
    this.tagify = new Tagify(this.$refs.input, this.tagifySettings);

    if (Object.keys(this.modelValue).length > 0) {
      this.loadInitialLocation('placeId' in this.modelValue);
    }

  },

  unmounted() {
    this.tagify.removeAllTags();
    this.tagify.destroy();
    this.tagify = null;
  },

  methods: {
    tagifyInput(e) {
      clearTimeout(this.timeout);

      const value = e.detail.value;
      this.tagify.whitelist = null;
      this.tagify.loading(true).dropdown.hide();

      this.timeout = setTimeout(() => {
        this.loadLocationSuggestions(value).then((results) => {
          this.tagify.whitelist = results;
          this.tagify.loading(false).dropdown.show(value);
        });
      }, 500);
    },

    loadLocationSuggestions(query) {
      return new Promise((resolve, reject) => {
        if (!query || query.length === 0) {
          resolve([]);
          return;
        }

        axios.post(this.route('frontend.api.gpa-search-input'), {input: query})
          .then(({data}) => resolve(data.suggestions))
          .catch((err) => reject(err));
      });
    },

    loadInitialLocation(hasPlaceId) {
      if (hasPlaceId) {
        this.tagify.loading(true);

        axios.post(this.route('frontend.api.gpa-search-place-id'), {place_id: this.modelValue.placeId})
          .then(({data}) => {
            this.tagify.addTags([{place_id: this.modelValue.placeId, value: data.location.formattedAddress}], true);

            this.tagify.loading(false);
          });
      } else if (this.loadInitialFromValue) {
        this.tagify.loading(true);

        this.tagify.addTags([{place_id: null, value: this.formatLocationForInput(this.modelValue)}]);

        this.tagify.loading(false);
      }
    },

    update(e) {
      let decodedValue = null;

      try {
        decodedValue = JSON.parse(e.detail.value);
      } catch {
        return;
      }

      if (!decodedValue || !Array.isArray(decodedValue)) {
        return;
      }

      const newValue = decodedValue[0].place_id;

      if (!newValue) {
        return;
      }

      axios.post(this.route('frontend.api.gpa-search-place-id'), {
        place_id: newValue,
      })
        .then(({data}) => {
          const location = data.location;
          delete location.formattedAddress;

          this.$emit('update:modelValue', location);
        });
    },

    cleared() {
      this.$emit('update:modelValue', {});
    },

    formatLocationForInput(location) {
      const address = {};

      if (location.address) {
        address.address = location.address;
      }

      if (location.city && location.state) {
        address.city = `${location.city}, ${location.state}`;
      } else if (location.city) {
        address.city = location.city;
      } else if (location.state) {
        address.city = location.state;
      }

      if (location.postalCode && location.country) {
        address.postalCode = `${location.postalCode}, ${location.country.toUpperCase()}`;
      } else if (location.postalCode) {
        address.postalCode = location.postalCode;
      } else if (location.country) {
        address.postalCode = location.country;
      }

      return Object.values(address).join(', ');
    },
  },
};
</script>
