<template>
  <div class="py-20">
    <JobSearchQuestion @next="nextQuestion" />
  </div>
</template>

<script>
import { usePage } from '@inertiajs/vue3';
import { useStore } from 'vuex';
import JobSearchQuestion from './JobSearchQuestion.vue';
import Search from '../../../../Mixins/Search.js';

export default {
  components: {
    JobSearchQuestion,
  },

  mixins: [Search],

  setup() {
    const page = usePage();
    useStore().commit('SurveyForm/clearJobSearch');
    useStore().commit('SurveyForm/setRequestData', page.props.requestData);
  },

  data() {
    return {
      totalSteps: 1,
      currentStep: 1,
      loading: false,
    };
  },

  methods: {
    nextQuestion(forceStep = null) {
      if (forceStep) {
        this.currentStep = forceStep;
      } else {
        this.currentStep += 1;
      }

      if (this.currentStep > this.totalSteps) {
        this.search();
      }
    },

    search() {
      this.loading = true;

      const query = this.jobSearchToQuery(this.$store.state.SurveyForm.jobSearch);
      this.$inertia.visit(this.route('frontend.jobs', query));
    },
  },
};
</script>
