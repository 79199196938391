<template>
  <div
    v-if="false"
    class="py-4 px-6 mb-4 bg-danger/40 rounded-md"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="__('profile.unsubscribed-message')" />
    <!-- eslint-enable -->
    <InertiaLink
      :href="route('frontend.profile.notifications.index')"
      class="text-primary hover:text-primary-dark"
    >
      {{ __('profile.unsubscribed-link') }}
    </InertiaLink>
  </div>

  <div class="rounded-t-md border border-gray-300 p-4 md:p-8">
    <div class="font-semibold text-3xl mb-2">
      {{ __('profile.menu.job-alert') }}
    </div>

    <div>
      {{ __('profile.job-alerts.change') }}
    </div>
  </div>

  <div class="rounded-b-md border border-t-0 border-gray-300 px-4 md:px-8">
    <template
      v-for="(jobSearch, i) in $store.state.ProfileJobAlertSettings.jobSearches"
      :key="`job-search-${jobSearch.id}`"
    >
      <JobSearch
        :index="i"
        @loading="loading = $event"
        @add="addJobSearch"
        @remove="removeJobSearch"
      />
    </template>

    <div class="grid grid-cols-12 py-4">
      <div class="lg:col-start-5 col-span-12 lg:col-span-10 lg:ml-7 sm:space-x-2 space-y-2 sm:space-y-0">
        <button
          type="button"
          class="w-full sm:w-auto md:w-auto bg-success hover:bg-success/80 text-white transition-colors px-4 py-2 rounded whitespace-nowrap"
          @click="addJobSearch(null)"
        >
          <span class="fa fa-plus mr-1" />
          {{ __('profile.job-alerts.add.add') }}
        </button>

        <button
          type="button"
          class="w-full sm:w-auto md:w-auto bg-success hover:bg-success/80 text-white transition-colors px-4 py-2 rounded whitespace-nowrap disabled:bg-success/40"
          :disabled="loading"
          @click="save"
        >
          {{ __('profile.job-alerts.save') }}
          <span
            v-show="loading"
            class="fa fa-spinner animate-spin ml-1"
          />
        </button>
      </div>
    </div>
  </div>

  <SavedSuccessModal ref="saved-success-modal" />
  <ErrorModal ref="saved-error-modal" />
</template>

<script>
import axios from 'axios';
import { usePage } from '@inertiajs/vue3';
import { useStore } from 'vuex';
import DefaultLayout from '../../../Layouts/Default.vue';
import ProfileLayout from '../../../Layouts/Profile.vue';
import JobSearch from './Components/JobSearch.vue';
import SavedSuccessModal from '../Components/SavedSuccessModal.vue';
import ErrorModal from '../../Auth/Components/ErrorModal.vue';

export default {
  components: {
    JobSearch,
    SavedSuccessModal,
    ErrorModal,
  },

  layout: [DefaultLayout, ProfileLayout],

  setup() {
    const page = usePage();
    useStore().commit('ProfileJobAlertSettings/setJobSearches', page.props.jobSeeker.jobSearches);
  },

  data() {
    return {
      loading: false,
    };
  },

  mounted() {
    for (const jobSearchKey in this.$store.state.ProfileJobAlertSettings.jobSearches) {
      if (!this.$store.state.ProfileJobAlertSettings.jobSearches[jobSearchKey].jobAge) {
        this.$store.state.ProfileJobAlertSettings.jobSearches[jobSearchKey].jobAge = 14;
      }

      if (!this.$store.state.ProfileJobAlertSettings.jobSearches[jobSearchKey].jobTypes) {
        this.$store.state.ProfileJobAlertSettings.jobSearches[jobSearchKey].jobTypes = [];
      }

      if (!this.$store.state.ProfileJobAlertSettings.jobSearches[jobSearchKey].maxJobAlertsSetting) {
        this.$store.state.ProfileJobAlertSettings.jobSearches[jobSearchKey].maxJobAlertsSetting = {};
      }
    }
  },

  methods: {
    addJobSearch(index) {
      const jobSearch = {
        keywords: [],
        location: { country: this.$page.props.requestData.country, radius: 40 },
        jobAge: 14,
        maxJobAlertsSetting: {},
        jobTypes: [],
      };

      this.$store.commit('ProfileJobAlertSettings/addJobSearch', { jobSearch, index });
    },

    removeJobSearch(index) {
      this.$store.commit('ProfileJobAlertSettings/removeJobSearch', index);
    },

    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(this.route('frontend.profile.job-alert-settings.update'), {
        jobSearches: this.$store.state.ProfileJobAlertSettings.jobSearches,
      })
        .then(() => {
          this.$refs['saved-success-modal'].open();
        })
        .catch(() => {
          this.$refs['saved-error-modal'].open();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
