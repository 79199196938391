import { createApp, h } from 'vue';
import { createInertiaApp, Link, Head } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from 'ziggy';
import { createI18n } from 'vue-i18n';
import { createStore } from 'vuex';

import DefaultLayout from './Layouts/Default.vue';
import IndexStore from './Stores/Index';
import SurveyFormStore from './Stores/SurveyForm';
import ProfileJobAlertSettingsStore from './Stores/Profile/JobAlertSettings';
import ProfileMyInformationStore from './Stores/Profile/MyInformation';

import GoogleAnalyticsPlugin from './Plugins/GoogleAnalytics';
import GoogleTagManagerPlugin from './Plugins/GoogleTagManager';
import TrackerPlugin from './Plugins/Tracker';

import '../css/app.scss';
import en from './lang/en.json';

createInertiaApp({
  resolve: name => {
    return resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue', {eager: true}))
      .then(page => {
        if (page.default.layout === undefined) {
          page.default.layout = DefaultLayout;
        }
        return page;
      });
  },

  async setup({ el, App, props, plugin }) {
    const ssrWorking = el.childNodes.length > 0;
    if (!props.initialPage.props.isProduction && !ssrWorking) {
      if (!props.initialPage.props.inertiaIsEnabled) {
        console.info('[dev] SSR not working, but is not enabled.');
      } else {
        const error = 'app.js - SSR not working?! Check the (docker) logs for any SSR error. Preventing the app from client-side rendering.';
        console.error(error);

        const errorElement = document.createElement('div');
        errorElement.innerHTML = `<h1><b>${error}</b></h1>`;
        el.appendChild(errorElement);

        throw error;
      }
    }

    const store = createStore({
      modules: {
        'Index': IndexStore,
        'SurveyForm': SurveyFormStore,
        'ProfileJobAlertSettings': ProfileJobAlertSettingsStore,
        'ProfileMyInformation': ProfileMyInformationStore,
      },
    });

    const app = createApp({
        render: () => h(App, props),
    })
      .use(plugin)
      .use(ZiggyVue)
      .use(createI18n({
        legacy: false,
        globalInjection: true,
        locale: props.initialPage.props.language,
        fallbackLocale: 'en',
        messages: {
          'en': en,
        },
      }))
      .use(store)
      .use(GoogleAnalyticsPlugin, { googleAnalyticsId: props.initialPage.props.config.googleAnalyticsId })
      .use(GoogleTagManagerPlugin)
      .use(TrackerPlugin)
      .mixin({
        methods: {
          route: (name, params, absolute, config = window.Ziggy) => window.route(name, params, absolute, config),
          asset: (name) => `/${props.initialPage.props.tenant}/${name}`,
          __(key, options) {
            const defaultOptions = {
              brand: props.initialPage.props.config.brandName,
            };
            return this.$t(key, Object.assign(defaultOptions, options));
          },
        },
      })
      .component('InertiaHead', Head)
      .component('InertiaLink', Link);

    if ('serviceWorker' in navigator && 'PushManager' in window && 'BroadcastChannel' in window) {
      app.config.globalProperties.$swRegistration = await navigator.serviceWorker.register('/sw.js');
      await app.config.globalProperties.$swRegistration.update();
      await app.config.globalProperties.$swRegistration.active.postMessage({ type: 'SET_CONFIG', config: {
        serverKey: props.initialPage.props.config.serverKey,
      }});
    }

    if (props.initialPage.props.jobSeeker) {
      app.config.globalProperties.$tracker.login(props.initialPage.props.jobSeeker);
    }

    app.mount(el);
  },
});
