<template>
  <div class="container max-w-2xl">
    <div class="flex justify-center my-20">
      <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
        Searching for a new job?
      </p>
    </div>

    <div class="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 mb-6">
      <MultiKeywordSuggestionInput
        v-model="$store.state.SurveyForm.jobSearch.keywords"
        class="w-full"
        placeholder="Job Title"
        :show-error="!canContinueKeywords && showError"
      />
      <LocationSuggestionInput
        v-model="$store.state.SurveyForm.jobSearch.location"
        class="w-full"
        :load-initial-from-value="false"
        placeholder="Location"
        :show-error="!canContinueLocation && showError"
      />
    </div>

    <div class="flex justify-center">
      <div class="flex items-center space-x-4">
        <button
          type="button"
          class="bg-controls hover:bg-controls-dark transition-colors text-white text-lg px-12 py-3 rounded"
          @click="next"
        >
          Search
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import MultiKeywordSuggestionInput from '../../../../Components/MultiKeywordSuggestionInput.vue';
import LocationSuggestionInput from '../../../../Components/LocationSuggestionInput.vue';

export default {
  components: {
    LocationSuggestionInput,
    MultiKeywordSuggestionInput,
  },

  emits: ['next'],

  data() {
    return {
      canContinueKeywords: false,
      canContinueLocation: false,
      showError: false,
    };
  },

  watch: {
    '$store.state.SurveyForm.jobSearch.keywords': {
      deep: true,
      handler: function(keywords) {
        this.canContinueKeywords = keywords && keywords.length > 0;
      },
    },

    '$store.state.SurveyForm.jobSearch.location': {
      deep: true,
      handler: function(location) {
        this.canContinueLocation = 'placeId' in location && location.placeId && location.placeId.length > 0;
      },
    },
  },

  methods: {
    next() {
      if (!this.canContinueKeywords || !this.canContinueLocation) {
        this.showError = true;
        return;
      }
      const selectedKeywords = cloneDeep(this.$store.state.SurveyForm.selectedKeywords);
      const otherKeywordIndex = selectedKeywords.indexOf('Other');
      if (otherKeywordIndex !== -1) {
        selectedKeywords.splice(otherKeywordIndex, 1);
      }

      this.$store.state.SurveyForm.jobSearch.keywords = selectedKeywords.concat(this.$store.state.SurveyForm.customKeywords).concat(this.$store.state.SurveyForm.jobSearch.keywords);

      this.$emit('next');
    },
  },
};
</script>
