<template>
  <Impersonate />
  <header>
    <div class="container">
      <div class="flex justify-between items-center my-4">
        <div class="md:hidden">
          <MobileMenu>
            <template #button="{ toggle }">
              <button
                type="button"
                @click="toggle"
              >
                <span class="fa fa-bars text-2xl" />
              </button>
            </template>
          </MobileMenu>
        </div>

        <Component
          :is="$page.props.jobSeeker ? 'a' : 'InertiaLink'"
          :href="$page.props.jobSeeker ? route('frontend.jobs') : route('frontend.index')"
        >
          <img
            :src="asset('logo.svg')"
            :alt="$page.props.config.brandName"
            class="h-8"
          >
        </Component>

        <div class="block md:hidden">
          <ProfileMenu>
            <template #button="{ toggle }">
              <button
                type="button"
                @click="toggle"
              >
                <span class="fal fa-user text-2xl" />
              </button>
            </template>
          </ProfileMenu>
        </div>

        <div class="hidden md:block space-x-6 text-sm">
          <InertiaLink :href="jobSeeker ? route('frontend.profile.job-alert-settings.index') : route('frontend.auth.login')">
            {{ __('menu.account') }}
          </InertiaLink>

          <InertiaLink :href="route('frontend.post-a-job')">
            {{ __('footer.buttons.post-a-job') }}
          </InertiaLink>
        </div>
      </div>
    </div>
  </header>

  <slot />

  <footer class="bg-footer mt-14 py-10 px-6">
    <div class="container text-white text-sm space-y-10">
      <div class="text-center">
        {{ __('footer.cookies.text') }}
        <InertiaLink
          :href="route('frontend.privacy-policy')"
          class="text-primary hover:text-primary-dark hover:underline"
        >
          {{ __('footer.cookies.link') }}
        </InertiaLink>
      </div>

      <div class="text-center">
        <a
          href="https://facebook.com/TrendingJobsInternational"
          rel="noopener noreferrer"
          target="_blank"
          class="bg-primary hover:bg-primary-dark transition-colors rounded px-4 py-3 hover:no-underline"
        >
          <span class="fab fa-facebook-square mr-2" />
          {{ __('footer.facebook-button') }}
        </a>
      </div>

      <div class="flex flex-col md:block text-center md:space-x-6 space-y-4 md:space-y-0">
        <InertiaLink :href="route('frontend.index')">
          {{ __('footer.buttons.home') }}
        </InertiaLink>
        <InertiaLink :href="route('frontend.index')">
          {{ __('footer.buttons.find-jobs') }}
        </InertiaLink>
        <InertiaLink :href="route('frontend.post-a-job')">
          {{ __('footer.buttons.post-a-job') }}
        </InertiaLink>
        <InertiaLink :href="route('frontend.faq')">
          {{ __('footer.buttons.faq') }}
        </InertiaLink>
        <InertiaLink :href="route('frontend.contact.index')">
          {{ __('footer.buttons.get-in-touch') }}
        </InertiaLink>
        <InertiaLink :href="route('frontend.privacy-policy')">
          {{ __('footer.buttons.privacy-policy') }}
        </InertiaLink>
        <InertiaLink :href="route('frontend.terms-and-conditions')">
          {{ __('footer.buttons.terms-and-conditions') }}
        </InertiaLink>
      </div>
    </div>
  </footer>
</template>

<script>
import MobileMenu from '../Components/MobileMenu.vue';
import ProfileMenu from '../Components/ProfileMenu.vue';
import Impersonate from '../Components/Impersonate.vue';

export default {
  components: {
    Impersonate,
    MobileMenu, ProfileMenu,
  },

  computed: {
    jobSeeker() {
      return this.$page.props.jobSeeker;
    },
  },
};
</script>
